import { Link } from 'gatsby';
import React from 'react';
import RingsImg from '../../images/rings.svg';


function CTASection() {
    return (
        <div className="lg:container lg:mx-auto  md:container-none lg:py-20 py-6 lg:px-24">
            <div className="bg-accentLight rounded-lg relative">
                <RingsImg className="absolute top-0 left-24" />
                <RingsImg className="absolute top-24 rotate-90 -right-6" />
                <div className="container mx-auto md:flex items-center justify-between py-24 lg:px-32 px-16">
                    <h2 className="text-gray-900">
                        <span className="text-3xl block font-bold">Ready to dive in?</span>
                        <span className="mt-5 block text-lg max-w-3xl">
                            Enhance your customer experience. Provides a hassle-free interface to manage your entire business needs. Helps to increase your ROI.
                        </span>
                    </h2>
                    <div className="mt-8 flex-shrink-0">
                        <div className="inline-flex rounded-md">
                            <Link to='/book-demo' className="inline-flex items-center justify-center rounded-md border border-transparent bg-accent px-5 py-3  font-semibold text-sm text-white uppercase">Book A Demo</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CTASection;