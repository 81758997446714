import React from 'react';
import MobileApp from '../../images/mobile-app.svg';
import BookOnline from '../../images/book-online.svg';
import CustomerRetention from '../../images/customer.svg';
import Reports from '../../images/reports.svg';
import MultipleLocationIcon from '../../images/multiple-location.svg';
import Reminders from '../../images/reminders.svg';
import StaffManagement from '../../images/staff-management.svg';
import KPI from '../../images/KPI.svg';
import AutoCreatedCustomerIcon from '../../images/auto-create-customer.svg';


const FEATURES = [
    {
        title: "Go with mobile first",
        description: "Using your fingertip, you can see and manage your entire business. To give seamless service to your customer, Pickmystylist offer your own brand mobile app.",
        icon: MobileApp
    },
    {
        title: "Book Online Appointment",
        description: "Let your clients view your real-time availability, schedule their own appointments and make payments anytime, anywhere, on any device.",
        icon: BookOnline
    },
    {
        title: "Auto-created customer profiles",
        description: "The customer profile will be instantly created under the appropriate shop once a shop owner has added the appointment.",
        icon: AutoCreatedCustomerIcon
    },
    {
        title: "Multi location",
        description: "Let salon owner can easily manage one or more branches in their fingertip at any time, anywhere, on any device.",
        icon: MultipleLocationIcon
    },
    {
        title: "Stay up to date",
        description: "Keep your staff up to date with automatic email and notifications so they instantly know about new, rescheduled, or cancelled appointments – even when they’re on the go.",
        icon: StaffManagement
    },
    {
        title: "Reminders",
        description: "Pickmystylist sends automatic booking confirmation emails, notification or SMS to your clients. Our online scheduling software also helps you reduce costly no-shows, automatically reminding clients of their bookings – by emails, notification or SMS.",
        icon: Reminders
    },
    {
        title: "Customer Retentions",
        description: "Boost your customer retention by enhancing your customer experience. Also, notify your clients about your offers.",
        icon: CustomerRetention
    },
    {
        title: "KPI Reporting",
        description: "Pickmystylist provides detailed reports with key indicators that assist you in determining whether an employee is delivering the expected work output.",
        icon: KPI
    },
    {
        title: "Business Insights",
        description: "Pickmystylist provide insights about your revenue, appointments, most-booked services, most-booked stylist and more. It can help to grow your business.",
        icon: Reports
    },
];

function Features() {
    return (
        <div className='lg:container  md:p-12 lg:mx-auto lg:px-16 sm:p-6  '>
            <h2 className='text-3xl font-semibold  text-center py-10'>Key Features</h2>
            <div className="grid lg:grid-cols-3 mx-5 md:grid-cols-2 sm:grid-cols-1 gap-16">
                {FEATURES.map((feature) => (
                    <div key={feature.title} className="border rounded-lg p-5">
                        <div className="mb-8">
                            <feature.icon />
                        </div>
                        <h3 className='text-xl font-semibold  mb-4'>{feature.title}</h3>
                        <p className='text-md'>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Features;