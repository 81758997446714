import React from 'react';


function Footer({ className }) {
    return (
        <div className={`py-6 bg-accentDark ${className}`}>
            <div className="container px-16 flex justify-between text-gray-300 text-xs mx-auto items-center">
  
                <div className="mx-auto">
                    <a href=''>Copyright © 2022 Orkaapps Pvt. Ltd.</a>
                </div>

            </div>
        </div>
    );
}

export default Footer;
