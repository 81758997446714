import React from 'react';

import AppointmentManagementImg from '../../images/appointment-management.png';
import CustomerManagementImg from '../../images/client-management.png';
import BusinessReportsImg from '../../images/business-reports.png';
import ShopManagementImg from '../../images/shop-management.png';

const ITEMS = [
    {
        title: "Appointment Management",
        description: "No more missed appointments or scribbled notes. Easily check your appointment schedule from anywhere, anytime, on any device.",
        image: AppointmentManagementImg
    },
    {
        title: "Client Management",
        description: "Go paperless with integrated intake forms, with all the client info. All of your client information is organized and up-to-date in Pickmystylist. Easily access each client’s full and current contact info anywhere, anytime.",
        image: CustomerManagementImg
    },
    {
        title: "Shop Management",
        description: "With your finger touch auto manage and monitor multiple shops from anywhere, anytime in the world.",
        image: ShopManagementImg,
    },
    {
        title: "Business Reports",
        description: "Generates a fully customizable insightful business report Provides a way to monitor your earnings, booking, and canceled reports. You will get all Insights to upgrade your business with your finger touch.",
        image: BusinessReportsImg
    },
];


function WhySection() {
    function renderItem(item, index) {
     
        if (index % 2 === 0) {
            return (
                <div  key={index} className="lg:container lg:mx-auto  container-none  items-center justify-center flex flex-wrap items-center  my-6 lg:px-10 md:px-20 sm:px-16 ">
                    <div className="lg:w-1/3 sm:w-1/2 w-2/2">
                        <img src={item.image} alt="img"/>
                    </div>
                    <div className="max-w-lg lg:ml-auto md:mr-auto">
                        <h3 className='lg:text-2xl  sm:text-2xl text-xl font-semibold  mb-4'>{item.title}</h3>
                        <p className='text-xl'>{item.description}</p>
                    </div>
                </div>
            )
        }

        return (
            <div key={index} className="lg:container lg:mx-auto  container-none items-center justify-center flex flex-wrap-reverse  items-center  my-6 lg:px-10  md:px-24 sm:px-16">
                <div className="max-w-lg mr-auto">
                        <h3 className='lg:text-2xl sm:text-2xl  text-xl  font-semibold  mb-4'>{item.title}</h3>
                        <p className='text-xl  '>{item.description}</p>
                    </div>
                <div className="lg:w-1/3 sm:w-1/2 w-2/2">
                    <img src={item.image} alt="img"/>
                </div>
            </div>
        )
    }

    return (
        <div className='lg:container lg:mx-auto md:container-none px-10 mb-18 lg:px-10 md:px-24 '>
            <h2 className='text-3xl font-semibold text-center py-10'>Why Pickmystylist?</h2>
            <p className='max-w-4xl text-center mx-auto text-xl mb-28'>Enhance your customer experience. Provides a hassle-free interface to manage your entire business needs. Helps to increase your ROI.</p>
            {ITEMS.map(renderItem)}

        </div>
    );
}

export default WhySection;