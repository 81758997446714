import * as React from "react"
import Banner from "./components/Banner"
import CTASection from "./components/CTASection"
import Features from "./components/Features"
import Footer from "./components/Footer"
import Header from "./components/Header"
import BusinessesSection from "./components/ProviderSection"
import WhySection from "./components/WhySection"

const IndexPage = () => {
  return (
    <React.StrictMode>
      <Header />
      <main>
        <Banner />
        <Features />
        <WhySection />
        <BusinessesSection />
        <CTASection />
        <Footer />
      </main>
    </React.StrictMode>
  )
}

export default IndexPage

export const Head = () => 
  <title>PickMyStylist.com | Software to manage and grow your business</title>
